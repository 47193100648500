import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getNftData = createAsyncThunk("nftData", async (data) => {
  try {
    const res = await api.get(`${API_URL}/api/getNftValue/${data}`);
    return {
      status: res?.status,
      data: res?.data,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const getNftTableData = createAsyncThunk(
  "nftTableData",
  async (data) => {
    try {
      const res = await api.get(
        `${API_URL}/api/getNftTableData/${data?.id}?page=${data?.page}`
      );
      return {
        status: res?.status,
        data: res?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);

export const getReferalData = createAsyncThunk(
  "getReferalData",
  async (data) => {
    try {
      const res = await api.get(
        `${API_URL}/api/getReferalData/${data?.id}?page=${data.page}`
      );
      return {
        status: res?.status,
        data: res?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);

export const getUserHistory = createAsyncThunk(
  "getHistory",
  async (data) => {
    try {
      const res = await api.get(
        `${API_URL}/api/getUserHistory/${data?.id}?page=${data?.page}`
      );
      return {
        status: res?.status,
        data: res?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);
export const getAllUsers = createAsyncThunk(
  "getAllUsers",
  async (data) => {
    try {
      const res = await api.get(
        `${API_URL}/api/getAllUsers?page=${data?.page}`
      );
      return {
        status: res?.status,
        data: res?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);

export const getAdminDashboard = createAsyncThunk(
  "getAdminDashboard",
  async (data) => {
    try {
      const res = await api.get(
        `${API_URL}/api/getAdminInfo`
      );
      return {
        status: res?.status,
        data: res?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);

export const getPayoutReq = createAsyncThunk(
  "getPayoutReq",
  async (data) => {
    try {
      const res = await api.get(
        `${API_URL}/api/getUserPayout`
      );
      return {
        status: res?.status,
        data: res?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);
export const changePaidStatus = createAsyncThunk("changePaidStatus", async (data) => {
  try {
    const res = await api.post(`${API_URL}/api/addPaidStatus/${data}`);
    return {
      status: res?.status,
      data: res?.data,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});
