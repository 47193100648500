import { configureStore } from "@reduxjs/toolkit";
import signup from "./reducer/signup";
import nftData from "./reducer/getNft";
import nftTable from "./reducer/getNftTable";
import getUserInfo from "./reducer/getUserInfo";
import referal from "./reducer/getReferal";
import history from "./reducer/getUserHistory";
import getProfileImage from "./reducer/getProfileImage";
import getFunds from "./reducer/getFunds";
import getAllUsers from "./reducer/getAllUsers";
import getAdminDashboard from "./reducer/getAdminDashboard";
import getPayout from "./reducer/getPayout";

export const store = configureStore({
  reducer: {
    profile: signup,
    nftData: nftData,
    nftTable: nftTable,
    profileInfo: getUserInfo,
    referal: referal,
    history: history,
    profileImage: getProfileImage,
    funds: getFunds,
    users: getAllUsers,
    adminDashboard: getAdminDashboard,
    payout: getPayout,
  },
});
