import SideDrawer from "../../../Components/Drawer/SideDrawer";
import "./AdminUserProfile.css";
import React from "react";
import { useState, useRef, useEffect } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import AdminAccountTable from "../../../Components/Table/Admin/AccountHistoryTable/AdminAccountTable";
import AdminReferralTable from "../../../Components/Table/Admin/MyReferralTable/AdminReferralTable";
import AdminUserCard from "../../../Components/Cards/AdminUserCard/AdminUserCard";
import {
  getReferalData,
  getUserHistory,
} from "../../../services/redux/middleware/getNft";
import { getProfileInfo } from "../../../services/redux/middleware/signin";
import ScreenLoader from "../../../Components/loader/ScreenLoader";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

export default function AdminUserProfile() {
  const navigate = useNavigate();
  const referralLink = "https://www.web3fund.ai/9881";
  const inputRef = useRef(null);
  const [referralID, setreferralID] = useState("");
  const [tableBtn, setTableBtn] = useState(1);
  const [selectedDropDown, setSelectedDropDown] = useState("All Time");
  const dispatch = useDispatch();
  const { id } = useParams();

  const userDataInfo = useSelector(
    (state) => state?.profileInfo?.userProfile?.data
  );
  const userDataInfoLoader = useSelector((state) => state?.profileInfo);

  const handleReferralCopy = async () => {
    try {
      await navigator.clipboard.writeText(referralLink);
      alert("Referral link copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  };

  useEffect(() => {
    const data = {
      id: id,
      page: 1,
    };
    dispatch(getReferalData(data));
  }, [id]);
  useEffect(() => {
    const data = {
      id: id,
      page: 1,
    };

    dispatch(getUserHistory(data));
  }, [id]);

  useEffect(() => {
    dispatch(getProfileInfo(id));
  }, [id]);

  return (
    <>
      {userDataInfoLoader?.loading && <ScreenLoader />}
      <SideDrawer showSidebar={true} style={{ overflowX: "hidden" }}>
        {/* header */}
        <div className="user-head">
          <div className="profile-info-contain-admin">
            <div className="profile-name-contain-admin">
              <div className="prof-name">
                <div className="profile-main">
                  <img
                    width={112}
                    height={112}
                    style={{ borderRadius: "100px" }}
                    src={
                      userDataInfo?.user?.profilePic
                        ? userDataInfo?.user?.profilePic
                        : "/Images/User Profile/profile-user.svg"
                    }
                    alt="Profile-img"
                  />
                </div>
                <p className="user-name">
                  {userDataInfo?.user?.name ? userDataInfo?.user?.name : "---"}
                </p>
              </div>
              <div className="referral-edit">
                <Button
                  onClick={() => {
                    navigate("/Dashboard/Profile-Detail");
                  }}
                  className="user-edit-btn"
                >
                  Edit Profile
                </Button>
                <div className="Referral-box">
                  <p>
                    Referral Link:{" "}
                    <span>
                      {" "}
                      https://www.web3fund.ai/id/
                      {userDataInfo?.user?.referralId
                        ? userDataInfo?.user?.referralId
                        : "No refferal"}
                    </span>
                  </p>{" "}
                  <img
                    src="/Images/User Profile/copy-userprofile.svg"
                    alt="copy-txt"
                    className="link-copy"
                    onClick={() => {
                      handleReferralCopy(userDataInfo?.user?.referralId);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="profile-info-rhs">
            <div className="user-heading">
              <p>Email:</p>
              <p>Telegram Handle:</p>
              <p>X Handle:</p>
              {/* <p>Referral Link:</p>
              <p>Referrer:</p> */}
              <p>Wallet Address:</p>
            </div>
            <div className="user-value">
              <p>
                {userDataInfo?.user?.email ? userDataInfo?.user?.email : "-"}
              </p>
              <p>
                {userDataInfo?.user?.telegramHandle
                  ? userDataInfo?.user?.telegramHandle
                  : "-"}
              </p>
              <p>
                {userDataInfo?.user?.xHandle
                  ? userDataInfo?.user?.xHandle
                  : "-"}
              </p>

              <p>
                {" "}
                {userDataInfo?.user?.walletAddress
                  ? `${userDataInfo?.user?.walletAddress?.slice(
                      0,
                      8
                    )}....${userDataInfo?.user?.walletAddress?.slice(-7)}`
                  : "-"}
              </p>
            </div>
          </div>
          <div className="p-info-rhs-mb">
            <div className="mb-r-1">
              <p className="user-heading-mb">Email:</p>
              <p className="user-value-mb">Jake@gmail.com</p>
            </div>
            <div className="mb-r-1">
              <p className="user-heading-mb">Telegram Handle:</p>
              <p className="user-value-mb">@jakey</p>
            </div>

            <div className="mb-r-1">
              <p className="user-heading-mb">X Handle:</p>
              <p className="user-value-mb">x.com/user/jakewilliams</p>
            </div>

            <div className="mb-r-1">
              <p className="user-heading-mb">Referral Link:</p>
              <p className="user-value-mb">https://www.web3fund.ai/9881</p>
            </div>
            <div className="mb-r-1">
              <p className="user-heading-mb">Referrer:</p>
              <p className="user-value-mb">Jakey@gmail.com</p>
            </div>

            <div className="mb-r-1">
              <p className="user-heading-mb">Wallet Address:</p>
              <p className="user-value-mb">0x55uejnnws44m44m2100po0aa</p>
            </div>
          </div>
        </div>
        <div className="user-profile-cards">
          <AdminUserCard
            ucardimg={"/Images/User Profile/dollar-icon.svg"}
            ucardh={"Total Deposits"}
            ucardvalue={`$ ${userDataInfo?.totalPrice?.toLocaleString()}`}
          />
          <AdminUserCard
            ucardimg={"/Images/User Profile/dollar-icon.svg"}
            ucardh={"Total Profits"}
            ucardvalue={`$${(
              userDataInfo?.totalValue - userDataInfo?.totalPrice
            )?.toLocaleString()}`}
          />
          <AdminUserCard
            ucardimg={"/Images/User Profile/nft-icon-card.svg"}
            ucardh={"Total NFTs"}
            ucardvalue={`${userDataInfo?.totalNfts}`}
          />
          <AdminUserCard
            ucardimg={"/Images/User Profile/referral-icon-card.svg"}
            ucardh={"Users Referred"}
            ucardvalue={`${userDataInfo?.userRefered}`}
          />
        </div>

        <div className="user-row3">
          <div className="table-above-row-admin">
            <div className="btns-table-main">
              <Button
                className={
                  tableBtn === 1 ? "btn-active-tb" : "btn-account-table"
                }
                onClick={() => {
                  setTableBtn(1);
                }}
              >
                Account History
              </Button>
              <Button
                className={
                  tableBtn === 0 ? "btn-active-tb" : "btn-referral-table"
                }
                onClick={() => {
                  setTableBtn(0);
                }}
              >
                My Referrals
              </Button>
            </div>

            <div className="table-top-lhs">
              {/* <button className="filter-btn">
                <img src="/Images/Dashboard/filter.svg" alt="/" /> Filter
              </button> */}
              <div className="alltime-drop">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdown-week"
                  >
                    <img src="/Images/Dashboard/calendar-2.svg" />{" "}
                    {selectedDropDown}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedDropDown("All Time");
                      }}
                    >
                      All Time
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => {
                        setSelectedDropDown("1 Year");
                      }}
                    >
                      1 Year
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedDropDown("90 Days");
                      }}
                    >
                      90 Days
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedDropDown("30 Days");
                      }}
                    >
                      30 Days
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedDropDown("7 Days");
                      }}
                    >
                      7 Days
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {/* My referrals */}
          {tableBtn === 0 ? (
            <AdminReferralTable selectedDropDown={selectedDropDown} />
          ) : (
            <AdminAccountTable />
          )}
        </div>
      </SideDrawer>
    </>
  );
}
