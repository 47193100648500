import React, { useEffect } from "react";
import "./MainDashboardInfo.css";
import SideDrawer from "../../../Components/Drawer/SideDrawer";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Linechart from "../../../Components/Linechart/Linechart";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import BarChart from "../../../Components/Barchart/Barchart";
import useMediaQuery from "@mui/material/useMediaQuery";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import IconButton from "@mui/material/IconButton";
import TableData from "../../../Components/TableData/TableData";
import { useSelector, useDispatch } from "react-redux";
import {
  getAdminDashboard,
  getAllUsers,
} from "../../../services/redux/middleware/getNft";
import ScreenLoader from "../../../Components/loader/ScreenLoader";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

function createData(
  hash,
  id,
  name,
  email,
  wallet,
  datejoin,
  referrals,
  nfts,
  deposits,
  profits,
  more
) {
  return {
    hash,
    id,
    name,
    email,
    wallet,
    datejoin,
    referrals,
    nfts,
    deposits,
    profits,
    more,
  };
}
const rows = [
  createData(
    "1",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),
  createData(
    "2",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),

  createData(
    "3",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),

  createData(
    "4",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),
  createData(
    "5",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),
  createData(
    "6",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),
  createData(
    "7",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),
  createData(
    "8",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),
  createData(
    "9",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),
  createData(
    "10",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),
  createData(
    "11",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),
  createData(
    "12",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),
  createData(
    "13",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),
  createData(
    "14",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),
  createData(
    "15",
    "09901",
    "Alan James",
    "Alanj33@gmail.com",
    "0x223.....33RE",
    "01/03/2024",
    "12",
    "3",
    "$100,000",
    "$50,000"
  ),
];

const AdminDashboard = () => {
  const [showTb, setShowTb] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [nftData, setNftsData] = useState();
  const [soldNftData, setSoldNftData] = useState();
  const [selectedDropDown, setSelectedDropDown] = useState("All Time");
  const [userDeposits, setUserDeposits] = useState();
  const [userTotalSales, setUserSales] = useState();

  const [searchQuery, setSearchQuery] = useState("");

  // const [nftDatas, setNftsData] = useState();
  const handleModalOpen = () => {
    // setNftsData(data);

    setShowTb(!showTb);
  };
  const allUsers = useSelector((state) => state?.users);

  const AdminDashboard = useSelector((state) => state?.adminDashboard);

  const dispatch = useDispatch();

  const querymd = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2); // Adjust this value as needed

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);

    const data = {
      page: page,
    };
    dispatch(getAllUsers(data));
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // for random images
  const images = [
    "/Images/Dashboard/dropup.svg",
    "/Images/Dashboard/dropdown.svg",
  ];

  // State to hold the randomly selected image
  const [randomImage, setRandomImage] = useState(null);

  // Function to select a random image
  const selectRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    setRandomImage(images[randomIndex]);
  };

  //dropdownmenu selector
  const [selectedMonth, setSelectedMonth] = useState("");

  const handleMonthClick = (event) => {
    setSelectedMonth(event.target.textContent);
  };

  const [data, setData] = useState(true);

  useEffect(() => {
    const data = {
      page: 1,
    };
    dispatch(getAdminDashboard());
    dispatch(getAllUsers(data));
  }, []);

  const ConvertDate = (originalDateStr) => {
    const originalDate = new Date(originalDateStr);
    const formattedDate = originalDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return formattedDate;
  };

  const filteredUserProfiles = allUsers?.users?.data?.userProfiles?.filter(
    (row) =>
      row?.user?.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      row?.user?.email?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      row?.user?.walletAddress
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase()) ||
      row?.user?.referralId?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const userFilterData = filteredUserProfiles?.filter((row) => {
    const currentDate = new Date();
    const createdAtDate = new Date(row.user.createdAt); // Assuming createdAt is a date string

    switch (selectedDropDown) {
      case "This Week":
        const oneWeekAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 7)
        );
        return createdAtDate >= oneWeekAgo;

      case "1 Year":
        const oneYearAgo = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        return createdAtDate >= oneYearAgo;
      case "90 Days":
        const ninetyDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 90)
        );
        return createdAtDate >= ninetyDaysAgo;
      case "30 Days":
        const thirtyDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 30)
        );
        return createdAtDate >= thirtyDaysAgo;
      case "7 Days":
        const sevenDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 7)
        );
        return createdAtDate >= sevenDaysAgo;
      default:
        return true; // Return true to include the profile in the "All" range
    }
  });

  function deposite() {
    const totalDepositCurrentMonth =
      AdminDashboard?.admin?.data?.totalDepositCurrentMonth;
    const totalDepositPreviousMonth =
      AdminDashboard?.admin?.data?.totalDepositPreviousMonth;

    if (totalDepositPreviousMonth === 0) {
      if (totalDepositCurrentMonth === 0) {
        // If both current and previous months' deposits are 0, return 0% change
        return 0;
      }
      // Handle the case where totalDepositPreviousMonth is 0,
      // considering it as a 100% increase from 0 to current month's deposit
      return 100;
    }

    const percentageChange =
      ((totalDepositCurrentMonth - totalDepositPreviousMonth) /
        totalDepositPreviousMonth) *
      100;

    return percentageChange;
  }

  const thisDeposite = deposite();

  function totalProfits() {
    const profileCurrent =
      AdminDashboard?.admin?.data?.totalValueCurrent -
      AdminDashboard?.admin?.data?.totalDepositCurrentMonth;
    const profilePrevious =
      AdminDashboard?.admin?.data?.totalValuePrevious -
      AdminDashboard?.admin?.data?.totalDepositPreviousMonth;

    console.log(profileCurrent, profilePrevious);
    if (profilePrevious === 0) {
      return 100;
    }

    const totalValue =
      ((profileCurrent - profilePrevious) / profilePrevious) * 100;
    return totalValue ? totalValue : 0;
  }

  function depositeNft() {
    const totalNFTsCurrent = AdminDashboard?.admin?.data?.totalNFTsCurrent;
    const totalNFTsPrevious = AdminDashboard?.admin?.data?.totalNFTsPrevious;

    if (totalNFTsPrevious === 0) {
      if (totalNFTsCurrent === 0) {
        // If both current and previous periods' NFT counts are 0, return 0% change
        return 0;
      }
      // Handle the case where totalNFTsPrevious is 0,
      // considering it as a 100% increase from 0 to current period's NFT count
      return 100;
    }

    const percentageChange =
      ((totalNFTsCurrent - totalNFTsPrevious) / totalNFTsPrevious) * 100;

    return percentageChange;
  }
  const thisNftData = depositeNft();
  const thisProfits = totalProfits();

  return (
    <>
      {allUsers.loading && <ScreenLoader />}
      <SideDrawer showSidebar={true} style={{ overflowX: "hidden" }}>
        <TableData
          nftDta={nftData}
          
          show={showTb}
          onHide={() => {
            setShowTb(false);
          }}
        />
        <div>
          <div className="maindashboardinfo__main">
            <div className="maindashboardinfo__main-left">
              <div className="maindashboardinfo__main-heading">
                <h6>Admin Dashboard</h6>
              </div>
              <div
                className={`maindashboardinfo-maincard ${
                  querymd ? "pe-0" : "pe-3"
                }`}
              >
                <Card
                  sx={{
                    width: "100%",
                    background: "#FFFFFF",
                    boxShadow: "none",
                    border: "1px solid #F0F1F3",
                  }}
                  style={{
                    color: "var(--Black-500---Primary, #667085)",
                    border: "1px solid #FOF1F3",
                    borderRadius: "8px",
                    boxshadow: "0px 4px 4px 0px #00000040 ",
                  }}
                >
                  <CardContent
                    sx={{
                      padding: "24px",
                      background: "#FFFFFF",
                      ...(querymd && {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }),
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                      }}
                      style={{
                        background: "#F9F9FC",
                        width: "32px",
                        height: "32px",
                        borderRadius: "50px",
                      }}
                    >
                      <img
                        src="/Images/Admin-Info-Dashboard/Icon.svg"
                        alt="money-recieve"
                      />
                    </Typography>
                    <Typography
                      sx={{ mb: 1.5 }}
                      color="text.secondary"
                      style={{
                        color: "#667085",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        paddingTop: "16px",
                        marginBottom: "0",
                        marginBottom: "0",
                      }}
                    >
                      Total Deposits
                    </Typography>
                    <Typography
                      variant="h5"
                      component="div"
                      style={{
                        color: "#1D1F2C",
                        fontSize: "24px",
                        fontWeight: "500",
                        paddingTop: "4px",
                        paddingBottom: "12px",
                      }}
                    >
                      $
                      {AdminDashboard?.admin?.data?.totalDeposit &&
                        (AdminDashboard?.admin?.data?.totalDeposit)?.toLocaleString()}
                    </Typography>

                    <Typography variant="body2">
                      <span
                        style={{
                          background:
                            thisDeposite >= 0 ? "#4FBE881F" : "#F04A4A1F",
                          borderRadius: "8px",
                          // width: "54px",
                          height: "27px",
                          padding: "4px 6px",
                          color:
                            thisDeposite >= 0
                              ? "rgb(79, 190, 136)"
                              : "rgb(240, 74, 74)",
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "19.2px",
                        }}
                      >
                        {/* {(
                          (AdminDashboard?.admin?.data
                            ?.totalDepositCurrentMonth -
                            AdminDashboard?.admin?.data
                              ?.totalDepositPreviousMonth) /
                          AdminDashboard?.admin?.data?.totalDepositPreviousMonth
                        ).toFixed(3)} */}
                        {deposite().toFixed(1)}%
                      </span>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#667085",
                          paddingLeft: "8px",
                          lineHeight: "22.4px",
                        }}
                      >
                        {thisDeposite >= 0 ? "Higher" : "Less"} than last month
                      </span>
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    width: "100%",
                    background: "#FFFFFF",
                    boxShadow: "none",
                    border: "1px solid #F0F1F3",
                  }}
                  style={{
                    color: "var(--Black-500---Primary, #667085)",
                    border: "1px solid #FOF1F3",
                    radius: "8px",
                    boxshadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <CardContent
                    sx={{
                      padding: "24px",
                      background: "#FFFFFF",
                      ...(querymd && {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }),
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                      }}
                      style={{
                        background: "#F9F9FC",
                        width: "32px",
                        height: "32px",
                        borderRadius: "50px",
                      }}
                    >
                      <img
                        src="/Images/Admin-Info-Dashboard/Icon (1).svg"
                        alt="money-recieve"
                      />
                    </Typography>
                    <Typography
                      sx={{ mb: 1.5 }}
                      color="text.secondary"
                      style={{
                        color: "#667085",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        paddingTop: "16px",
                        marginBottom: "0",
                      }}
                    >
                      Total Profits
                    </Typography>
                    <Typography
                      variant="h5"
                      component="div"
                      style={{
                        color: "#1D1F2C",
                        fontSize: "24px",
                        fontWeight: "500",
                        paddingTop: "4px",
                        paddingBottom: "12px",
                      }}
                    >
                      $
                      {AdminDashboard?.admin?.data?.totalDeposit &&
                        (
                          AdminDashboard?.admin?.data?.totalValue -
                          AdminDashboard?.admin?.data?.totalDeposit
                        )?.toLocaleString()}
                    </Typography>

                    <Typography variant="body2">
                      <span
                        style={{
                          background:
                            thisProfits >= 0 ? "#4FBE881F" : "#F04A4A1F",
                          borderRadius: "8px",
                          height: "27px",
                          padding: "4px 6px",

                          color:
                            thisProfits >= 0 ? "rgb(79, 190, 136)" : "#F04A4A",
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "19.2px",
                        }}
                      >
                        {totalProfits().toFixed(3)}%
                      </span>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#667085",
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "19.2px",
                          marginLeft: "5px",
                        }}
                      >
                        {thisProfits >= 0 ? "Higher" : "Less"} than last month
                      </span>
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    width: "100%",
                    background: "#FFFFFF",
                    boxShadow: "none",
                    border: "1px solid #F0F1F3",
                  }}
                  style={{
                    color: "var(--Black-500---Primary, #667085)",
                    border: "1px solid #FOF1F3",
                    radius: "8px",
                    boxshadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <CardContent
                    sx={{
                      padding: "24px",
                      background: "#FFFFFF",
                      ...(querymd && {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }),
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                      }}
                      style={{
                        background: "#F9F9FC",
                        width: "32px",
                        height: "32px",
                        borderRadius: "50px",
                      }}
                    >
                      <img
                        src="/Images/Admin-Info-Dashboard/Icon (2).svg"
                        alt="NFT-SALES"
                      />
                    </Typography>
                    <Typography
                      sx={{ mb: 1.5 }}
                      color="text.secondary"
                      style={{
                        color: "#667085",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        paddingTop: "16px",
                        marginBottom: "0",
                      }}
                    >
                      Total NFT Sales
                    </Typography>
                    <Typography
                      variant="h5"
                      component="div"
                      style={{
                        color: "#1D1F2C",
                        fontSize: "24px",
                        fontWeight: "500",
                        paddingTop: "4px",
                        paddingBottom: "12px",
                      }}
                    >
                      {AdminDashboard?.admin?.data?.totalNFTs &&
                        AdminDashboard?.admin?.data?.totalNFTs}
                    </Typography>

                    <Typography variant="body2">
                      <span
                        style={{
                          background:
                            thisNftData >= 0 ? "#4FBE881F" : "#F04A4A1F",
                          borderRadius: "8px",
                          height: "27px",
                          padding: "4px 6px",
                          color:
                            thisNftData >= 0 ? "rgb(79, 190, 136)" : "#F04A4A",
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "19.2px",
                        }}
                      >
                        {thisNftData?.toFixed(3)}%
                      </span>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#667085",
                          marginLeft: "5px",
                        }}
                      >
                        {thisNftData >= 0 ? "Higher" : "Less"} than last month
                      </span>
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    width: "100%",
                    background: "#FFFFFF",
                    boxShadow: "none",
                    border: "1px solid #F0F1F3",
                  }}
                  style={{
                    color: "var(--Black-500---Primary, #667085)",
                    border: "1px solid #FOF1F3",
                    borderRadius: "8px",
                    boxshadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <CardContent
                    sx={{
                      padding: "24px",
                      background: "#FFFFFF",
                      ...(querymd && {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }),
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                      }}
                      style={{
                        background: "#F9F9FC",
                        width: "32px",
                        height: "32px",
                        borderRadius: "50px",
                      }}
                    >
                      <img
                        src="/Images/Admin-Info-Dashboard/Icon (3).svg"
                        alt="New-Users"
                      />
                    </Typography>
                    <Typography
                      sx={{ mb: 1.5 }}
                      color="text.secondary"
                      style={{
                        color: "#667085",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        paddingTop: "16px",
                        marginBottom: "0",
                      }}
                    >
                      New Users
                    </Typography>
                    <Typography
                      variant="h5"
                      component="div"
                      style={{
                        color: "#1D1F2C",
                        fontSize: "24px",
                        fontWeight: "500",
                        paddingTop: "4px",
                        paddingBottom: "12px",
                      }}
                    >
                      {AdminDashboard?.admin?.data?.totalUsers &&
                        AdminDashboard?.admin?.data?.totalUsers}
                    </Typography>

                    <Typography variant="body2">
                      <span
                        style={{
                          background: "#F04A4A1F",
                          borderRadius: "8px",
                          height: "27px",
                          padding: "4px 6px",
                          color: "#F04A4A",
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "19.2px",
                        }}
                      >
                        -0.4%
                      </span>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#667085 !important",
                          marginLeft: "5px",
                        }}
                      >
                        Less than last month
                      </span>
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>

          {/* table */}

          <div className="row-3 " style={{ overflowX: "auto" }}>
            <div className="table-above-row-info">
              <div className="search-bar">
                <div className="table-main-head-info">
                  <p className="user-heading"> Users </p>
                  <div className="search-input-icon">
                    <img
                      src="/Images/Admin-Info-Dashboard/search-icon.svg"
                      alt="search-icon"
                      className="search-icon"
                      style={{
                        position: "absolute",
                        left: "4%",
                        top: "45%",
                        transform: "translateY(-50%)",
                      }}
                    />
                    <input
                      type="text"
                      placeholder="Search..."
                      className="search-input"
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="table-top-lhs">
                <div className="drop-main">
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="dropdown-week"
                    >
                      <img src="/Images/Dashboard/calendar-2.svg" />{" "}
                      {selectedDropDown}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setSelectedDropDown("All Time");
                        }}
                      >
                        All Time
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                        onClick={() => {
                          setSelectedDropDown("This Week");
                        }}
                      >
                        This Week
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => {
                          setSelectedDropDown("1 Year");
                        }}
                      >
                        1 Year
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setSelectedDropDown("90 Days");
                        }}
                      >
                        90 Days
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setSelectedDropDown("30 Days");
                        }}
                      >
                        30 Days
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setSelectedDropDown("7 Days");
                        }}
                      >
                        7 Days
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* <div className="table-view-img">
                <img src="/Images/Dashboard/column-contain.svg" />
                <img
                  src="/Images/Dashboard/flex-contain.svg"
                  className="flex-img"
                />
              </div> */}
              </div>
            </div>
            <>
              {allUsers?.users?.data?.userProfiles &&
              allUsers?.users?.data?.userProfiles !== undefined &&
              allUsers?.users?.data?.userProfiles.length > 0 ? (
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table sx={{ overflowX: "auto" }} aria-label="simple table">
                    <TableHead>
                      <TableRow className="columns-name">
                        <TableCell className="column-head">#</TableCell>
                        <TableCell
                          className="column-head"
                          style={{ textAlign: "left" }}
                        >
                          ID
                        </TableCell>
                        <TableCell
                          className="column-head"
                          style={{ textAlign: "left" }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          className="column-head"
                          style={{ textAlign: "left" }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          className="column-head"
                          style={{ textAlign: "left" }}
                        >
                          Wallet
                        </TableCell>
                        <TableCell
                          className="column-head"
                          style={{ textAlign: "left" }}
                        >
                          Date Joined
                        </TableCell>
                        <TableCell
                          className="column-head"
                          style={{ textAlign: "left" }}
                        >
                          Referrals
                        </TableCell>
                        <TableCell
                          className="column-head"
                          style={{ textAlign: "left" }}
                        >
                          NFTs
                        </TableCell>
                        <TableCell
                          className="column-head"
                          style={{ textAlign: "left" }}
                        >
                          Deposits
                        </TableCell>
                        <TableCell
                          className="column-head"
                          style={{ textAlign: "left" }}
                        >
                          Profits
                        </TableCell>
                        <TableCell className="column-head"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userFilterData.length > 0
                        ? userFilterData?.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  color: "var(--Black-500---Primary, #1D1F2C)",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  lineHeight: "160%",
                                  textAlign: "left",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(`/userProfile/${row?.user?._id}`);
                                }}
                              >
                                {row?.index}
                              </TableCell>
                              <TableCell
                                style={{
                                  color: "var(--Black-500---Primary, #1D1F2C)",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "160%",
                                  textAlign: "left",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(`/userProfile/${row?.user?._id}`);
                                }}
                              >
                                {row?.user?.referralId}
                              </TableCell>
                              <TableCell
                                style={{
                                  color: "var(--Black-500---Primary, #1D1F2C)",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "160%",
                                  textAlign: "left",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(`/userProfile/${row?.user?._id}`);
                                }}
                              >
                                {row?.user?.name
                                  ? `${row?.user?.name}`
                                  : "-   -    -"}
                                {/* {row?.user?.name.length > 10
                                ? `${row?.user?.name.substring(0, 10)}...`
                                : row?.user?.name} */}
                              </TableCell>
                              <TableCell
                                style={{
                                  color: "var(--Black-500---Primary, #1D1F2C)",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "160%",
                                  textAlign: "left",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(`/userProfile/${row?.user?._id}`);
                                }}
                              >
                                {row?.user?.email
                                  ? `${row?.user?.email.slice(
                                      0,
                                      5
                                    )}...${row?.user?.email.slice(-10)}`
                                  : "-   -    -"}
                              </TableCell>
                              <TableCell
                                style={{
                                  color: "var(--Black-500---Primary, #1D1F2C)",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "160%",
                                  textAlign: "left",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(`/userProfile/${row?.user?._id}`);
                                }}
                              >
                                {row?.user?.walletAddress
                                  ? `${row?.user?.walletAddress.slice(
                                      0,
                                      5
                                    )}...${row?.user?.walletAddress.slice(-5)}`
                                  : "-   -   -"}
                              </TableCell>
                              <TableCell
                                style={{
                                  color: "var(--Black-500---Primary, #1D1F2C)",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "160%",
                                  textAlign: "left",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(`/userProfile/${row?.user?._id}`);
                                }}
                              >
                                {ConvertDate(row?.user?.createdAt)}
                              </TableCell>
                              <TableCell
                                style={{
                                  color: "var(--Black-500---Primary, #667085)",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  lineHeight: "160%",
                                  textAlign: "left",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(`/userProfile/${row?.user?._id}`);
                                }}
                              >
                                {row?.referalLength}
                              </TableCell>

                              <TableCell
                                style={{
                                  color: "var(--Black-500---Primary, #667085)",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "160%",
                                  textAlign: "left",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(`/userProfile/${row?.user?._id}`);
                                }}
                              >
                                {row?.totalNfts}
                              </TableCell>
                              <TableCell
                                style={{
                                  color: "var(--Black-500---Primary, #667085)",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "160%",
                                  textAlign: "left",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(`/userProfile/${row?.user?._id}`);
                                }}
                              >
                                {(row?.totalPrice)?.toLocaleString()}
                              </TableCell>
                              <TableCell
                                style={{
                                  color: "var(--Black-500---Primary, #667085)",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "160%",
                                  textAlign: "left",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate(`/userProfile/${row?.user?._id}`);
                                }}
                              >
                                {(
                                  row?.totalValue - row?.totalPrice
                                )?.toLocaleString()}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  disabled={row?.nftData?.length === 0 && true}
                                  // onClick={() => setOpen(!open)}
                                  onClick={() => {
                                    setNftsData(row?.nftData);
                                    
                                    handleModalOpen();
                                  }}
                                >
                                  {/* {open ? ( */}
                                  {showTb ? (
                                    <VisibilityOffOutlinedIcon />
                                  ) : (
                                    <VisibilityOutlinedIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        : "No recode found"}
                    </TableBody>
                  </Table>
                  <hr className="hr-above"></hr>
                  <div className="table__row">
                    <p>
                      Showing {allUsers?.users?.data?.recordsPerPage} items per
                      page
                    </p>
                    <Stack spacing={8} className="stack-contain">
                      <Pagination
                        count={allUsers?.users?.data?.totalPages}
                        variant="outlined"
                        shape="rounded"
                        siblingCount={0}
                        boundaryCount={2}
                        page={currentPage}
                        onChange={handlePageChange}
                      />
                    </Stack>
                  </div>
                </TableContainer>
              ) : (
                <div className="maindashboard__empty-text">
                  <img src="/Images/Admin-Info-Dashboard/Frame-4.svg" alt="/" />
                  <h6>No users available</h6>
                  <p>There is currently no user information available</p>
                </div>
              )}
            </>
          </div>
        </div>
      </SideDrawer>
    </>
  );
};

export default AdminDashboard;
