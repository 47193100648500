import Modal from "react-bootstrap/Modal";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { changePaidStatus, getPayoutReq } from "../../services/redux/middleware/getNft";
import { ErrorToast, SuccessToast } from "../toast/Toast";

const PayoutData = (props) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = React.useState(false);
  const ConvertDate = (originalDateTimeStr) => {
    const originalDateTime = new Date(originalDateTimeStr);

    const formattedDateTime = originalDateTime?.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      // second: "2-digit",
    });
    return formattedDateTime;
  };
  async function UpdateStatis(data) {
    setLoader(true);
    dispatch(changePaidStatus(data)).then((res) => {
      if (res?.payload?.status === 200) {
        console.log(data);
        setLoader(false);
        props.onHide();
        dispatch(getPayoutReq())
        SuccessToast("Paid Successfully");
      } else {
        setLoader(false);
        ErrorToast(res?.payload?.message);
      }
    });
  }
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-tabledata"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          <img
            src={"/Images/cross.svg"}
            className="close-btn"
            onClick={props.onHide}
            alt="/"
          />
        </Modal.Header>
        <Modal.Body>
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="inner-column-tb">#</TableCell>
                  <TableCell className="inner-column-tb">NFT Name</TableCell>
                  <TableCell className="inner-column-tb">NFT ID</TableCell>
                  <TableCell className="inner-column-tb">
                    Investment Date
                  </TableCell>
                  <TableCell className="inner-column-tb">
                    Investment Amount
                  </TableCell>
                  <TableCell className="inner-column-tb">NFT Value</TableCell>
                  <TableCell className="inner-column-tb">Payable Amount</TableCell>
                  <TableCell className="inner-column-tb">Status</TableCell>
                  <TableCell className="inner-column-tb"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.nftDta?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    {/* <TableCell
                    style={{
                      color: "#0F68FF",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "22.4px",
                      textAlign: "start",
                    }}
                  >
                    {row.buydate} 
                    {row?.nftName}
                    {ConvertDate(row?.createdAt)}
                  </TableCell> */}
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {/* {row?.nftId} */}
                      {/* {row?.value} */}
                      {row?.nftDetails?.nftName}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {row?.nftDetails?.nftId}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {ConvertDate(row?.nftDetails?.txDate)}
                      {/* {row?.nftPrice?.toLocaleString()} */}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {row?.nftDetails?.nftPrice?.toLocaleString()}
                      {/* {(row?.value / row?.quantity)?.toLocaleString()} */}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {row?.nftDetails?.value?.toLocaleString()}

                      {/* {row?.transfer?"Sold":"Active"} */}
                    </TableCell>

                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                   {parseFloat(row?.payoutAmount)?.toFixed(2)}

                      {/* {row?.transfer?"Sold":"Active"} */}
                    </TableCell>
                    <TableCell
                      style={{
                        color: row?.paid ? "#4FBE88" : "#F24444",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                        
                      }}
                    >
                      <p
                        style={{
                          background: row?.paid ? "#EAF7F1" : "#FFCCCC",
                          display: "flex",
                          justifyContent: "center",
                          padding: "6px 12px",
                          borderRadius: "8px",
                        }}
                      >
                        {row?.paid ? "Paid" : "Unpaid"}
                      </p>
                    </TableCell>

                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                        cursor: "pointer",
                      }}
                    >
                      {!row?.paid && (
                        <img
                          onClick={() => {
                            UpdateStatis(row?._id);
                          }}
                          src="/Images/tick.svg"
                          alt=""
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PayoutData;
