import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { ToastContainer } from "react-toastify";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Profiledetail from "./Pages/Dashboard/ProfileDetail/Profiledetail";
import Referrals from "./Pages/Dashboard/Referrals/Referrals";
import ClaimProfits from "./Components/ClaimProfits/ClaimProfits";
import AdminDashboard from "./Pages/Dashboard/AdminDashboard/AdminDashboard";
import MainDashboardInfo from "./Pages/Dashboard/MainDashboardInfo/MainDashboardInfo";
import AdminUserProfile from "./Pages/Dashboard/AdminUserProfile/AdminUserProfile";
import { store } from "./services/redux/store";
import ProtectedRoute from "./utils/protectedRoute/ProtectedRoute";

import {
  hooks as walletConnectV2Hooks,
  walletConnectV2,
} from "./utils/Web3Connection/connectors/walletConnectV2";
import {
  hooks as metaMaskHooks,
  metaMask,
} from "./utils/Web3Connection/connectors/metaMask";

import useEagerConnect from "./hooks/Web3Connection/useEagerConnector";
import LogIn from "./Pages/login/Login";
import { Provider } from "react-redux";
import PullRequest from "./Pages/Dashboard/PayoutRequest/PayoutRequest";
const connectors = [
  [walletConnectV2, walletConnectV2Hooks],
  [metaMask, metaMaskHooks],
];
function App() {
  useEagerConnect();
  return (
    <>
      <Provider store={store}>
        <Web3ReactProvider connectors={connectors}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            // theme="light"
          />
          <BrowserRouter>
            <Routes>
              {/* <Route path="/" element={<Dashboard />} /> */}
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <MainDashboardInfo />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/fundDataStats"
                element={
                  <ProtectedRoute>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/PayoutRequests"
                element={
                  <ProtectedRoute>
                    <PullRequest />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Profile-Detail"
                element={
                  <ProtectedRoute>
                    <Profiledetail />
                  </ProtectedRoute>
                }
              />
              {/* <Route path="/Referrals" element={<Referrals />} />
              <Route path="/ClaimProfits" element={<ClaimProfits />} /> */}
              <Route
                path="/userProfile/:id"
                element={
                  <ProtectedRoute>
                    <AdminUserProfile />
                  </ProtectedRoute>
                }
              />
              <Route path="/login" element={<LogIn />} />
            </Routes>
          </BrowserRouter>
        </Web3ReactProvider>
      </Provider>
    </>
  );
}

export default App;
