import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import "./TableData.css";
import { useState } from "react";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";

export default function TableData(props) {
  const { nftDta, soldNft } = props;

  const ConvertDate = (originalDateStr) => {
    const originalDate = new Date(originalDateStr);
    const formattedDate = originalDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return formattedDate;
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-tabledata"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          <img
            src={"/Images/cross.svg"}
            className="close-btn"
            onClick={props.onHide}
          />
        </Modal.Header>
        <Modal.Body>
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="inner-column-tb" width={"50px"}>
                    #
                  </TableCell>
                  {/* <TableCell className="inner-column-tb" width={"180px"}>
                    NFT Name
                  </TableCell> */}
                  <TableCell className="inner-column-tb" width={"90px"}>
                    NFT ID
                  </TableCell>
                  <TableCell className="inner-column-tb" width={"170px"}>
                    Purchase Date
                  </TableCell>
                  <TableCell className="inner-column-tb" width={"110px"}>
                    Amount
                  </TableCell>
                  <TableCell className="inner-column-tb" width={"115px"}>
                    NFT Value
                  </TableCell>
                  <TableCell className="inner-column-tb" width={"115px"}>
                    Status
                  </TableCell>

                  <TableCell className="inner-column-tb" width={"165px"}>
                    Tx Hash
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nftDta?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    {/* <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {row.buydate} 
                      {row?.nftName}
                      {ConvertDate(row?.createdAt)}
                    </TableCell> */}
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {row?.nftId}
                      {/* {row?.value} */}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {ConvertDate(row?.txDate)}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {row?.nftPrice?.toLocaleString()}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {(row?.value / row?.quantity)?.toLocaleString()}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {row?.transfer?"Sold":"Active"}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        window.open(
                          `https://etherscan.io/tx/${row?.tx}`,
                          "_blank"
                        );
                      }}
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                        cursor: "pointer",
                      }}
                    >
                      {row?.tx &&
                        `${row?.tx.slice(0, 5)}...${row?.tx.slice(-5)}`}
                    </TableCell>
                  </TableRow>
                ))}

               
              </TableBody>
            </Table>
          </TableContainer>
        </Modal.Body>
      </Modal>
    </>
  );
}
