import React, { useEffect } from "react";
import "./AdminDashboard.css";
import SideDrawer from "../../../Components/Drawer/SideDrawer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ScreenLoader from "../../../Components/loader/ScreenLoader";
import Dropdown from "react-bootstrap/Dropdown";
import { ErrorToast, SuccessToast } from "../../../Components/toast/Toast";
import {
  AddFund,
  UpdateIpfs,
  getFundsData,
} from "../../../services/redux/middleware/fund";
import { useDispatch, useSelector } from "react-redux";

const AdminDashboard = () => {
  const [selectedYear, setSelectedYear] = useState("Year");
  const [month, setMonth] = useState("Month");

  const navigate = useNavigate();

  function createData(
    hash,
    nft,
    amount,
    transaction,
    dateinvested,
    status,
    profit,
    more
  ) {
    return {
      hash,
      nft,
      amount,
      transaction,
      dateinvested,
      status,
      profit,
      more,
    };
  }
  const rows = [
    createData("1", "January", "$100", "$100", "$100,000", "$100"),
    createData("2", "February", "$100", "$100", "$100", "$100"),
    createData("3", "March", "$100", "$100", "$100", "$100"),
    createData("4", "April", "$100", "$100", "$100", "$100"),
    createData("5", "May", "$100", "$100", "$100", "$100"),
    createData("6", "June", "$100", "$100", "$100", "$100"),
    createData("7", "July", "$100", "$100", "$100", "$100"),
    createData("8", "August", "$100", "$100", "$100", "$100"),
    createData("9", "September", "$100", "$100", "$100", "$100"),
    createData("10", "October", "$100", "$100", "$100", "$100"),
    createData("11", "November", "$100", "$100", "$100", "$100"),
    createData("12", "December", "$100", "$100", "$100", "$100"),
    // createData("5", 356, 16.0, 49, 3.9,24, "30 Days Left"),
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2); // Adjust this value as needed
  const [deposite, setDeposite] = useState();
  const [pnlPer, setPnlPer] = useState();
  const [pnlDollar, setPnlDollar] = useState();
  const [loader, setLoader] = useState(false);
  const [monthCount, setMonthCount] = useState();
  const [fundsYear, setFundsYear] = useState(2024);
  const [ipfsHash, setIpfsHash] = useState();

  const dispatch = useDispatch();
  const fundsData = useSelector((state) => state?.funds);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleYearSelect = (year) => {
    setSelectedYear(year);
    // You can do further processing with the selected year here
    console.log("Selected year:", year);
  };

  const handleMonthSelect = (year) => {
    setMonth(year);
    // You can do further processing with the selected year here
  };

  // Assuming rows is your data array
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // for random images
  const images = [
    "/Images/Dashboard/dropup.svg",
    "/Images/Dashboard/dropdown.svg",
  ];

  // State to hold the randomly selected image
  const [randomImage, setRandomImage] = useState(null);

  // Function to select a random image
  const selectRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    setRandomImage(images[randomIndex]);
  };

  //dropdownmenu selector
  const [selectedMonth, setSelectedMonth] = useState("");

  const handleMonthClick = (event) => {
    setSelectedMonth(event.target.textContent);
  };
  const formValidation = () => {
    if (selectedYear === "Year") {
      ErrorToast("Please Enter year");
      return false;
    } else if (month === "Month") {
      ErrorToast("Please Enter Month");
      return false;
    } else if (!deposite) {
      ErrorToast("Please Add monthly deposite");
      return false;
    } else if (!pnlPer) {
      ErrorToast("Please Enter PNL Percentage");
      return false;
    } else if (!pnlDollar) {
      ErrorToast("Please Enter PNL dollar");
      return false;
    }
  };
  async function UpdateFund() {
    const res = formValidation();
    if (res === false) {
      return false;
    }
    setLoader(true);
    try {
      const data = {
        month: month,
        year: selectedYear,
        deposite: deposite,
        pnlpercentage: pnlPer,
        pnlDollar: pnlDollar,
        monthCount: monthCount,
      };
      dispatch(AddFund(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          SuccessToast("Funds Updated Successfully");
          const data = {
            year: 2024,
          };
          dispatch(getFundsData(data));
        } else {
          setLoader(false);

          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      setLoader(true);
      console.error("Error:", error);
    }
  }
  function CalculateTotalValue(data) {
    let totalValues = 0;
    data?.forEach((element) => {
      totalValues += element?.newDeposite;
    });

    return totalValues;
  }
  function updateTheIPFS() {
    setLoader(true);
    dispatch(UpdateIpfs()).then((res) => {
      if (res?.payload?.status === 200) {
        setLoader(false);
        SuccessToast("Hash Updated Successfully");
        setIpfsHash(res?.payload?.data?.ipfsHash);
        console.log(res);
      } else {
        setLoader(false);

        ErrorToast(res?.payload?.message);
      }
    });
  }
  useEffect(() => {
    const data = {
      year: fundsYear,
      page: 1,
    };
    dispatch(getFundsData(data));
  }, [fundsYear]);
  return (
    <div>
      {loader && <ScreenLoader />}
      <SideDrawer showSidebar={true} style={{ overflowX: "hidden" }}>
        <div className="admindashboard__main">
          <div className="admindashboard__main-left">
            <div className="admindashboard__main-heading">
              <h6>Fund Data Stats</h6>
              <p>Analyse and track your performance</p>
            </div>
            <div className="admindashboard__main-heading-txt">
              <h6>Update/Edit latest info</h6>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic1"
                  className="dropdown__menu"
                  value=""
                >
                  {selectedYear}
                </Dropdown.Toggle>
                <Dropdown.Menu className="show1">
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => handleYearSelect(2022)}
                  >
                    <p className="dropdown__text-year">2022</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => handleYearSelect(2023)}
                  >
                    <p>2023</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => handleYearSelect(2024)}
                  >
                    <p>2024</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => handleYearSelect(2025)}
                  >
                    <p>2025</p>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic1"
                  className="dropdown__menu"
                  value=""
                >
                  {month}
                </Dropdown.Toggle>
                <Dropdown.Menu className="show1">
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => {
                      handleMonthSelect("January");
                      setMonthCount(0);
                    }}
                  >
                    <p>January</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => {
                      handleMonthSelect("Feburary");
                      setMonthCount(1);
                    }}
                  >
                    <p>Feburary</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => {
                      handleMonthSelect("March");
                      setMonthCount(2);
                    }}
                  >
                    <p>March</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => {
                      handleMonthSelect("April");
                      setMonthCount(3);
                    }}
                  >
                    <p>April</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => {
                      handleMonthSelect("May");
                      setMonthCount(4);
                    }}
                  >
                    <p>May</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => {
                      handleMonthSelect("June");
                      setMonthCount(5);
                    }}
                  >
                    <p>June</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => {
                      handleMonthSelect("July");
                      setMonthCount(6);
                    }}
                  >
                    <p>July</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => {
                      handleMonthSelect("August");
                      setMonthCount(7);
                    }}
                  >
                    <p>August</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => {
                      handleMonthSelect("September");
                      setMonthCount(8);
                    }}
                  >
                    <p>September</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => {
                      handleMonthSelect("October");
                      setMonthCount(9);
                    }}
                  >
                    <p>October</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => {
                      handleMonthSelect("November");
                      setMonthCount(10);
                    }}
                  >
                    <p>November</p>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown__text"
                    onClick={() => {
                      handleMonthSelect("December");
                      setMonthCount(11);
                    }}
                  >
                    <p>December</p>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <div>
                <input
                  onChange={(e) => {
                    setDeposite(e.target.value);
                  }}
                  type="number"
                  placeholder="New Deposits Amount"
                  className="admindashboard__main-input"
                />
                <input
                  onChange={(e) => {
                    setPnlPer(e.target.value);
                  }}
                  type="number"
                  placeholder="Enter PNL % "
                  className="admindashboard__main-input"
                />
                <input
                  onChange={(e) => {
                    setPnlDollar(e.target.value);
                  }}
                  type="number"
                  placeholder="Enter PNL $"
                  className="admindashboard__main-input  btm-input"
                />
              </div>
            </div>
            <div className="admindashboard__main-btn">
              <button onClick={UpdateFund}>Update</button>
            </div>
            <div className="admindashboard__main-text">
              <p className="admindashboard__main-text-p1">
                Update Data to IPFS
              </p>
              <button
                onClick={() => {
                  updateTheIPFS();
                }}
                className="admindashboard__main-text-btn"
              >
                Update IPFS
              </button>

              <p className="admindashboard__main-text-p2">Latest IPFS Hash:</p>
              <p className="admindashboard__main-text-p3">
                {ipfsHash && `ipfs://${ipfsHash}/`}
              </p>
            </div>
          </div>
          <div
            className=" admindashboard__main-right "
            style={{ overflowX: "auto" }}
          >
            <div className="table-above-row">
              <p className="table-main-head1">Financials</p>
              <div className="table-top-lhs1">
                {/* <button className="filter-btn" onClick={selectRandomImage}>
                {randomImage && <img src={randomImage} alt="Random Image" />} Filter
                </button> */}
                <div>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="dropdown-week"
                    >
                      <img src="/Images/Dashboard/calendar-2.svg" />
                      {fundsYear}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setFundsYear(2024);
                        }}
                      >
                        2024
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setFundsYear(2023);
                        }}
                      >
                        2023
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setFundsYear(2025);
                        }}
                      >
                        2025
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* <div className="table-view-img">
                <img src="/Images/Dashboard/column-contain.svg" />
                <img
                  src="/Images/Dashboard/flex-contain.svg"
                  className="flex-img"
                />
              </div> */}
              </div>
            </div>
            <div className="table-above-row">
              <p className="table-main-head2">Total Deposits:</p>
              <div className="table-top-lhs2">
                {/* <button className="filter-btn" onClick={selectRandomImage}>
                {randomImage && <img src={randomImage} alt="Random Image" />} Filter
                </button> */}
                <p>
                  $
                  {CalculateTotalValue(
                    fundsData?.fund?.data?.fundsData
                  )?.toLocaleString()}
                </p>
                {/* <div className="table-view-img">
                <img src="/Images/Dashboard/column-contain.svg" />
                <img
                  src="/Images/Dashboard/flex-contain.svg"
                  className="flex-img"
                />
              </div> */}
              </div>
            </div>

            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
              <Table sx={{ overflowX: "auto" }} aria-label="simple table">
                <TableHead>
                  <TableRow className="columns-name">
                    <TableCell className="admin__column-head">#</TableCell>
                    <TableCell className="admin__column-head">Month</TableCell>
                    <TableCell className="admin__column-head">
                      $Change
                    </TableCell>
                    <TableCell className="admin__column-head">
                      %Change
                    </TableCell>
                    {/* <TableCell className="admin__column-head">Date Invested </TableCell>
                  <TableCell className="admin__column-head">Status</TableCell>
                  <TableCell className="admin__column-head">
                    Profit Distribution
                  </TableCell> */}
                    <TableCell className="admin__column-head">
                      New Deposits
                    </TableCell>
                    <TableCell className="admin__column-head">
                      Total Fund
                    </TableCell>

                    {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fundsData?.fund?.data?.fundsData?.map((row, index) => {
                    if (index > 0) {
                    }
                    const currentPnlDollars = parseInt(row?.pnldollars);
                    const currentPnlDollarsPer = parseInt(row?.pnlpercentage);

                    const previousPnlDollars = parseInt(
                      fundsData?.fund?.data?.fundsData[index - 1]?.pnldollars
                    );
                    const previousPnlDollarsPer = parseInt(
                      fundsData?.fund?.data?.fundsData[index - 1]?.pnlpercentage
                    );
                    const arrowImg =
                      index > 0 ? (
                        currentPnlDollars >= previousPnlDollars ? (
                          <img
                            src="/Images/Dashboard/dropup.svg"
                            alt="/"
                            className="arrow-img"
                          />
                        ) : (
                          <img
                            src="/Images/Dashboard/dropdown.svg"
                            alt="/"
                            className="arrow-img"
                          />
                        )
                      ) : (
                        <img
                          src="/Images/Dashboard/dropup.svg"
                          alt="/"
                          className="arrow-img"
                        />
                      );

                    const arrowImgPer =
                      index > 0 ? (
                        currentPnlDollarsPer >= previousPnlDollarsPer ? (
                          <img
                            src="/Images/Dashboard/dropup.svg"
                            alt="/"
                            className="arrow-img"
                          />
                        ) : (
                          <img
                            src="/Images/Dashboard/dropdown.svg"
                            alt="/"
                            className="arrow-img"
                          />
                        )
                      ) : (
                        <img
                          src="/Images/Dashboard/dropup.svg"
                          alt="/"
                          className="arrow-img"
                        />
                      );

                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "160%",
                            textAlign: "start",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "160%",
                            textAlign: "start",
                          }}
                        >
                          {row?.month}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            textAlign: "start",
                            display: "flex",
                          }}
                        >
                          {arrowImg}$
                          {parseInt(row?.pnldollars)?.toLocaleString()}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            textAlign: "start",
                          }}
                        >
                          {arrowImgPer}
                          {row?.pnlpercentage}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            textAlign: "start",
                          }}
                        >
                          ${row?.newDeposite?.toLocaleString()}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            textAlign: "start",
                          }}
                        >
                          ${row?.totalFunds}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <hr className="hr-above"></hr>

              {/* <div className="table__row">
                <p>Showing 12 items per page</p>
                <Stack spacing={8} className="stack-contain">
                  <Pagination
                    count={4}
                    variant="outlined"
                    shape="rounded"
                    siblingCount={0}
                    boundaryCount={2}
                  />
                </Stack>
              </div> */}
              {/* <TablePagination
              rowsPerPageOptions={[4, 10, 25]} // Customize options as needed
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            </TableContainer>
          </div>
        </div>
      </SideDrawer>
    </div>
  );
};

export default AdminDashboard;
