import React from "react";
import { useState, useEffect } from "react";
import { ErrorToast, SuccessToast } from "../../toast/Toast";
import { Modal } from "react-bootstrap";
import "./SignIn.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signin } from "../../../services/redux/middleware/signin";

export default function SignIn(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loader, setLoader] = useState(false);
  const formValidation = () => {
    if (!email) {
      ErrorToast("Please Enter Email");
      return false;
    } else if (!password) {
      ErrorToast("Please Enter Password");
      return false;
    }
  };
  async function loginweb2() {
    const res = formValidation();
    if (res === false) {
      return false;
    }
    setLoader(true);
    try {
      const data = {
        email: email,
        password: password,
      };
      dispatch(signin(data)).then((res) => {
        console.log("this is the userData",res)
        if (res?.payload?.status === 200) {
          setLoader(false);
          localStorage.setItem("admin", true);
          localStorage.setItem("token", res?.payload?.token);
          navigate("/");
        } else {
          setLoader(false);

          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-whole-signin "
      show={props.show}
      // onHide={() => props.setModalShows(false)}
    >
      <Modal.Header
        style={{ background: "white", color: "black", maxwidth: "680px" }}
        className="main-section-signin"
      >
        {/* <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        <img
          src={"/Images/cross.svg"}
          className="close-btn-img"
          onClick={() => props.setModalShows(false)}
        /> */}
      </Modal.Header>
      <Modal.Body
        style={{
          background: "white",

          borderTop: "none",
          color: "black",
          width: "100%",
          height: "100%",
          justifyContent: "center",
        }}
        className="main-section-signin"
      >
        <div className="Web3modal__heading">
          <h1>Sign In</h1>
          <p>Please fill your email and password to sign In.</p>
          {/* <div
            onClick={() => {
              logingoogle();
            }}
            className="Web3modal__heading-google "
          >
            <img src="/Images/Signin/google-icon-1.svg" alt="/" />
            <p>Sign in with Google</p>
          </div>
          <div className=" align-items-center justify-content-center  meta-cards ">
            <div className="meta-new " onClick={connectMetaMask1}>
              <img src="/Images/Metmask.svg" alt="Metamask" />
            </div>
            <div className="meta-connect-new" onClick={trustWallet}>
              <img src="/Images/walletconnect-img.svg" alt="Wallet-connect" />
            </div>
          </div> */}
        </div>

        <div className="web3modal__text">
          <div className="web3modal__email">
            <h5>Email address</h5>
            <input
              type="text"
              placeholder="Enter your email"
              className="shadow-btn"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="web3modal__email">
            <h5>Password</h5>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter your password"
              className="shadow-btn"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <button onClick={togglePasswordVisibility}>
            {showPassword ? <img src="/Images/eye.svg" /> : <img  src="/Images/eyeoff.svg" />}
            </button>
          </div>
          <div className="web3modal__btn">
            <button
              onClick={() => {
                loginweb2();
              }}
            >
              Sign In
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
