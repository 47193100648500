import SideDrawer from "../../../Components/Drawer/SideDrawer";
import React from "react";
import Form from "react-bootstrap/Form";

import { Button } from "@mui/material";
import { useState } from "react";
import "./Profiledetail.css";
export default function Profiledetail() {
  const defaultprofile = "/Images/Dashboard/profile-detail.svg";
  const [imageSrc, setImageSrc] = useState(defaultprofile);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <SideDrawer showSidebar={true}>
        <div className="profile-whole">
          <p className="profile-head">Profile Details</p>
          <div className="profile-div">
            <label htmlFor="file-input">
              <img
                src={imageSrc}
                alt="Profile-img"
                className={
                  imageSrc !== defaultprofile ? "profile-img" : "d-block"
                }
              />
            </label>
            <input
              id="file-input"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <p className="profile-txt">Add Profile Photo</p>
          </div>

          {/* <div className="profile-div">
            <img src="/Images/Dashboard/profile-detail.svg" alt="Profile-img" />
            <p className="profile-txt">Add Profile Photo</p>
          </div> */}
          <div className="div2 w-100">
            <div className="w-100">
              <Form.Group>
                <Form.Label className="input-label">Referral ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter referral ID"
                  className="referral-input"
                />
              </Form.Group>
            </div>
            <div className="div2-r2  w-100">
              <Form.Group>
                <Form.Label className="input-label">Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  className="referral-input"
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label className="input-label">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  className="referral-input"
                />
              </Form.Group>
            </div>
            <div className="div2-r3  w-100">
              <Form.Group>
                <Form.Label className="input-label">Telegram Handle</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter telegram handle"
                  className="referral-input"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="input-label">X Handle</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter X handle"
                  className="referral-input"
                />
              </Form.Group>
            </div>
          </div>
          <div className="div3-btn">
            <Button className="profile-connect-btn">Connect</Button>
          </div>
        </div>
      </SideDrawer>
    </>
  );
}
