import React, { useState } from "react";
import "./login.css";
import SignIn from "../../Components/modal/SignIn/SignIn";
export default function LogIn() {
  const [shows, setModalsShow] = useState(true);

  return (
    <>
      <div className="SignUp-PG">
        <SignIn show={shows} setModalShows={setModalsShow}/>
      </div>
    </>
  );
}
