import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import React, { useEffect, useState } from "react";
import "./MyReferralTable.css";
import UserTableData from "../../../UserTableData/UserTableData";
import { useSelector, useDispatch } from "react-redux";
import TableData from "../../../TableData/TableData";
import { getReferalData } from "../../../../services/redux/middleware/getNft";
import { useParams } from "react-router-dom";

export default function AdminReferralTable({selectedDropDown}) {
  const [showTb, setShowTb] = useState(false);
  const [nftDatas, setNftsData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [soldNfts, setSoldNfts] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleModalOpen = (data, soldNft) => {
    setNftsData(data);
    setSoldNfts(soldNft);

    setShowTb(!showTb);
  };
  const thisisrefer = useSelector((state) => state?.referal?.referalDetail);

  const ConvertDate = (originalDateStr) => {
    const originalDate = new Date(originalDateStr);
    const formattedDate = originalDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return formattedDate;
  };
  const getNftVlaue = (data) => {
    let totalValue = 0;
    data.forEach((nft) => {
      totalValue += nft.value;
    });
    return totalValue?.toLocaleString();
  };

  const userFilterData = thisisrefer?.data?.data?.filter((row) => {
    const currentDate = new Date();
    const createdAtDate = new Date(row.createdAt); // Assuming createdAt is a date string

    switch (selectedDropDown) {
      case "This Week":
        const oneWeekAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 7)
        );
        return createdAtDate >= oneWeekAgo;

      case "1 Year":
        const oneYearAgo = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        return createdAtDate >= oneYearAgo;
      case "90 Days":
        const ninetyDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 90)
        );
        return createdAtDate >= ninetyDaysAgo;
      case "30 Days":
        const thirtyDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 30)
        );
        return createdAtDate >= thirtyDaysAgo;
      case "7 Days":
        const sevenDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 7)
        );
        return createdAtDate >= sevenDaysAgo;
      default:
        return true; // Return true to include the profile in the "All" range
    }
  });

  const handleChangePagitation = (event, value) => {
    // Handle page change here

    const data = {
      id: id,
      page: value ? value : 1,
    };
    dispatch(getReferalData(data));

    setCurrentPage(value);
  };
  function Row(props) {
    const { row, index } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell
            component="th"
            scope="row"
            style={{
              color: " #1D1F2C",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "160%",
              textAlign: "center",
            }}
          >
            {/* {index + 1} */}
            {row?.userNumber}
          </TableCell>
          <TableCell
            style={{
              color: " #1D1F2C",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%",
              textAlign: "left",
            }}
          >
            {row?.name}
          </TableCell>
          <TableCell
            style={{
              color: " #1D1F2C",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%",
              textAlign: "left",
            }}
          >
            {row?.email}
          </TableCell>
          <TableCell
            style={{
              color: " #1D1F2C",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%",
              textAlign: "left",
            }}
          >
            {row?.referralId}
          </TableCell>
          <TableCell
            style={{
              color: " #1D1F2C",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%",
              textAlign: "left",
            }}
          >
            {ConvertDate(row?.createdAt)}
          </TableCell>
          <TableCell
            style={{
              color: " #1D1F2C",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%",
              textAlign: "left",
            }}
          >
            {row?.nfts?.length}
          </TableCell>
          <TableCell
            style={{
              color: " #1D1F2C",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%",
              textAlign: "cleft",
            }}
          >
            ${getNftVlaue(row?.nfts)}
          </TableCell>

          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              disabled={
                row?.nfts?.length === 0 && true
              }
              // onClick={() => setOpen(!open)}
              onClick={() => {
                handleModalOpen(row?.nfts, row?.soldNfts);
              }}
            >
              {/* {open ? ( */}
              {showTb ? (
                <VisibilityOffOutlinedIcon />
              ) : (
                <VisibilityOutlinedIcon />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              borderBottom: "none",
              padding: 0,
            }}
            colSpan={12}
          ></TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <TableData
        nftDta={nftDatas}
        soldNft={soldNfts}
        show={showTb}
        onHide={() => {
          setShowTb(false);
        }}
      />

      {thisisrefer &&
      thisisrefer !== undefined &&
      thisisrefer?.data?.data?.length > 0 ? (
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow className="columns-user">
                <TableCell className="column-head">#</TableCell>
                <TableCell className="column-head" style={{textAlign : "left"}} >Name</TableCell>
                <TableCell className="column-head" style={{textAlign : "left"}} >Email</TableCell>
                <TableCell className="column-head" style={{textAlign : "left"}} >ID</TableCell>
                <TableCell className="column-head" style={{textAlign : "left"}} >Date</TableCell>
                <TableCell className="column-head" style={{textAlign : "left"}} >No.of NFTs</TableCell>
                <TableCell className="column-head" style={{textAlign : "left"}} >NFTs Value</TableCell>
                <TableCell className="column-head"  >View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userFilterData && userFilterData !== undefined
                ? userFilterData?.map((row, index) => (
                    <Row key={index} row={row} index={index} />
                  ))
                : "No Recode Found"}
            </TableBody>
          </Table>

          <Stack spacing={8} className="stack-contain pt-4">
            <Pagination
              count={thisisrefer?.data?.totalPages}
              page={currentPage}
              onChange={handleChangePagitation}
              variant="outlined"
              shape="rounded"
              siblingCount={0}
              boundaryCount={2}
            />
          </Stack>
        </TableContainer>
      ) : (
        <div className="no-referral-contain">
          <img src="/Images/Frame 4.svg" alt="No Referral Found" />
          <p className="no-referral-txt">No referrals available</p>
          <p className="no-referral-des">
            You currently haven't referred any new users to Web3Fund{" "}
          </p>
        </div>
      )}
    </>
  );
}
