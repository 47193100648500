import "./Drawer.css";
import { Container, InputAdornment, TextField } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useAuth from "../../hooks/Web3Connection/useAuth";
import Web3Modal from "../modal/Web3Modal";
import Avatar from "@mui/material/Avatar";

import { useWeb3React } from "@web3-react/core";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { Menu, MenuItem, makeStyles } from "@mui/material";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";

import { useMediaQuery } from "react-responsive";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    height: "100%",
    overflowX: "hidden",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      fontFamily: "Montserrat",
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "white",
  zIndex: "2",
}));

const AvatarTopDiv = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.up("md")]: {
    width: "100%",
    minWidth: 768,
  },
  [theme.breakpoints.down("sm")]: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    right: "auto",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-end",

    gap: theme.spacing(2),
  },
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "space-between",
  padding: "26px 24px",
  borderBottom: "none",
  zIndex: "2",
}));

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const themeMui = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

export default function SideDrawer({ children, showSidebar }) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery({
    query: "(max-width: 1064px)",
  });

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedotherItem, setSelectedOtherItem] = useState(null);

  const handleListItemClick = (index) => {
    console.log("clicked", index);
    setSelectedOtherItem(null);
    setSelectedItem(index);
  };
  const handleOtherListItemClick = (index) => {
    console.log("clicked", index);
    setSelectedItem(null);
    setSelectedOtherItem(index);
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    console.log("this is clicked");
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const opens = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    
  };

  const handleLogout = () => {    
    handleClose();
  };

  const handleSignIn = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { text: "Admin Dashboard", path: "/", disabled: false },
    // { text: "Users", path: "/userProfile", disabled: false },
    { text: "Fund Data Stats", path: "/fundDataStats" , disabled: false },
    { text: "Payout Requests", path: "/PayoutRequests", disabled: false },
    { text: "Trade Web3Fund NFT", path: "", disabled: true },
    // { text: "Trade Web3Fund NFT", path: "/Dashboard/Report", disabled: true },
    { text: "Chat", path: "", disabled: true },
  ];
  const dashicons = [
    
    "/Images/Dashboard/dashboard.svg",
    "/Images/document-text.svg",
    "/Images/Sidebar/payLight.svg",
    "/Images/Dashboard/tradeweb3.svg",
    "/Images/Dashboard/latestnews.svg",
    "/Images/Dashboard/tradeweb3.svg",
    "/Images/Dashboard/chat.svg",
  ];
  const dashiconsactive = [
    
    "/Images/Sidebar/discover.svg",
    "/Images/Sidebar/document-text.svg",
    "/Images/Sidebar/pull.svg",
    "/Images/Sidebar/box.svg",
    "/Images/Sidebar/sms.svg",
    "/Images/Sidebar/headphone.svg",
    "/Images/Sidebar/message-question.svg",
  ];
  const OthermenuItems = [
    { text: "Help", path: "" , disabled: true},
    { text: "Feedback", path: "" , disabled: true },
  ];
  const otherdashicons = [
    "/Images/Dashboard/help.svg",
    "/Images/Dashboard/Feedback.svg",
  ];

  let users = JSON.parse(localStorage.getItem("user"));

  let filteredMenuItems = [];
  let filteredOtherMenuItems = [];

  if (users?.role === 1) {
    filteredMenuItems = menuItems.filter((item) =>
      ["Home", "File Manager", "Admin"].includes(item.text)
    );
  } else {
    filteredMenuItems = menuItems.filter((item) => item.text !== "Admin");
    filteredOtherMenuItems = OthermenuItems.filter(
      (item) => item.text !== "Admin"
    );
  }

  // for connect wallet
  // const [wallet, SetWallet] = useState(false);
  const [token, setToken] = useState();

  async function handleConnect() {
    localStorage.clear();
    navigate("/login");
  }

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  return (
    <>
      <ThemeProvider theme={themeMui}>
        {showSidebar && (
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
              <Toolbar style={{ position: "relative", zIndex: "2" }}>
                {" "}
                <IconButton
                  color="#0000"
                  aria-label="open drawer"
                  onClick={isMobile ? handleDrawerToggle : handleDrawerOpen}
                  edge="start"
                  sx={{
                    mr: 2,
                    ...(open && { display: "none" }),
                    position: "absolute",
                    zIndex: 999,
                  }}
                >
                  <MenuIcon
                    style={{ position: "relative", zIndex: 999 }}
                    color={"black"}
                  />
                </IconButton>
                <div>
                  <AvatarTopDiv style={{ boxShadow: "none", zIndex: 1 }}>
                    <div
                      id="basic-button"
                      className="Avatar-top-div"
                      aria-controls={opens ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={opens ? "true" : undefined}
                    >
                      <div className="notify-search">
                        <div className="setting-notify">
                          <div className="nav__Right">
                          <Dropdown className="navbar__notification">
                              <Dropdown.Toggle className="navbar__notification__dropdown">
                                <img
                                  src="/Images/Dashboard/notification.svg"
                                  alt="notification"
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                              // style={{ background: "#F8F8F899", color: "red" }}
                              >
                                <h2 className="navbar__notification__dropdown__title">
                                  Notifications
                                </h2>
                                <div>
                                  <Dropdown.Item href="">
                                   
                                      <div className="dot-heading">
                                        <div className="blue-dot"></div>
                                        <div className=" w-100">
                                          <div>
                                            <p className="notify-heading">
                                              Lorem ipsum
                                            </p>
                                            <p className="notify-subtxt">
                                              Lorem ipsum dolor sit amet
                                              consectetur
                                            </p>
                                          </div>
                                          <div className="notify-txt-btm">
                                            <p className="hour-txt">1h ago</p>
                                            <p className="read-txt">
                                              Mark as read
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                 
                                  </Dropdown.Item>
                                  <Dropdown.Item href="">
                                   
                                      <div className="dot-heading">
                                        <div className="blue-dot"></div>
                                        <div className=" w-100">
                                          <div>
                                            <p className="notify-heading">
                                              Lorem ipsum
                                            </p>
                                            <p className="notify-subtxt">
                                              Lorem ipsum dolor sit amet
                                              consectetur
                                            </p>
                                          </div>
                                          <div className="notify-txt-btm">
                                            <p className="hour-txt">1h ago</p>
                                            <p className="read-txt">
                                              Mark as read
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    
                                  </Dropdown.Item>
                                  <Dropdown.Item href="">
                                    <div className="navbar__notification__dropdown__container__inner">
                                      <div className="dot-heading">
                                        <div className="blue-dot"></div>
                                        <div className=" w-100">
                                          <div>
                                            <p className="notify-heading">
                                              Lorem ipsum
                                            </p>
                                            <p className="notify-subtxt">
                                              Lorem ipsum dolor sit amet
                                              consectetur
                                            </p>
                                          </div>
                                          <div className="notify-txt-btm">
                                            <p className="hour-txt">1h ago</p>
                                            <p className="read-txt">
                                              Mark as read
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item href="">
                                  
                                      <div className="dot-heading">
                                        <div className="blue-dot"></div>
                                        <div className=" w-100">
                                          <div>
                                            <p className="notify-heading">
                                              Lorem ipsum
                                            </p>
                                            <p className="notify-subtxt">
                                              Lorem ipsum dolor sit amet
                                              consectetur
                                            </p>
                                          </div>
                                          <div className="notify-txt-btm">
                                            <p className="hour-txt">1h ago</p>
                                            <p className="read-txt">
                                              Mark as read
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                  
                                  </Dropdown.Item>
                                  <Dropdown.Item href="">
                                   
                                      <div className="dot-heading">
                                        <div className="blue-dot"></div>
                                        <div className=" w-100">
                                          <div>
                                            <p className="notify-heading">
                                              Lorem ipsum
                                            </p>
                                            <p className="notify-subtxt">
                                              Lorem ipsum dolor sit amet
                                              consectetur
                                            </p>
                                          </div>
                                          <div className="notify-txt-btm">
                                            <p className="hour-txt">1h ago</p>
                                            <p className="read-txt">
                                              Mark as read
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    
                                  </Dropdown.Item>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="vertical-line"></div>

                        {/* <div>
                          <Avatar
                            onClick={() =>
                              navigate("/Dashboard/Profile-Detail")
                            }
                            alt="Profile Picture"
                            src="/Images/Dashboard/Profile-top.svg"
                            // src={users?.profilePic}
                            sx={{ width: 40.5, height: 40.5 }}
                            className="avatar-img"
                          />
                        </div> */}
                        <div
                          onClick={() => {
                            handleConnect();
                          }}
                          eventKey={2}
                        >
                          <button className="btn-wallet">
                            {token ? "Logout" : "Log In"}
                          </button>
                        </div>
                      </div>

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={opens}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      ></Menu>
                    </div>
                  </AvatarTopDiv>
                </div>
                {/* <Typography variant="h6" noWrap component="div">
            Persistent drawer
          </Typography> */}
              </Toolbar>
            </AppBar>
            {!isMobile && (
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: " #fff",
                  },
                }}
                style={{ zIndex: 1, position: "relative" }}
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,

                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                  },
                }}
                variant="persistent"
                anchor="left"
                open={open}
              >
                <DrawerHeader>
                  <img
                    src="/Images/Dashboard/web3fund-dash-logo.svg"
                    // width={"30px"}
                    // height={"30px"}
                    className="ms-3 sidebar__image"
                    alt="Logo"
                    onClick={() => {window.location.href = ("https://web3fund.ai")}}
                  />
                  <IconButton
                    onClick={handleDrawerClose}
                    style={{ color: "#000" }}
                  >
                    {theme.direction === "ltr" ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                </DrawerHeader>
                <Divider />
                <List className="List-div">
                  <p className="items-main-heading">General Menu</p>
                  {filteredMenuItems?.map(({ text, path, disabled }, index) => (
                    <ListItem
                      selected={index === selectedItem}
                      key={text}
                      // disabled={disabled}
                      disablePadding
                      divider={false}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "22.4px",
                      }}
                    >
                      <ListItemButton
                        component={Link}
                        disabled={disabled}

                        to={path}
                        onClick={() => handleListItemClick(index)}
                        selected={index === selectedItem}
                        className={
                          index === selectedItem
                            ? "list-item-active"
                            : "list-item"
                        }
                        sx={{
                          "& .MuiListItemText-root": {
                            fontSize: "14px",
                          },
                          "& .MuiListItemText-primary": {
                            fontSize: "14px",
                          },
                        }}
                      >
                        <ListItemIcon
                          minWidth={"24px"}
                          className="list-icons"
                          selected={index === selectedItem}
                          sx={{
                            "& ..MuiListItemIcon-root": {
                              minWidth: "24px",
                            },
                          }}
                        >
                          { index === selectedItem ?
                           <img
                           src={dashiconsactive[index]}
                           alt={`Icon ${index + 1}`}
                         />
                         :
                          <img
                            src={dashicons[index]}
                            alt={`Icon ${index + 1}`}
                          />
                          }
                        </ListItemIcon>
                        <ListItemText
                          selected={index === selectedItem}
                          className={
                            index === selectedItem
                              ? "list-item-active"
                              : "list-item"
                          }
                          primary={text}
                        />
                        {disabled && (
                          <img src="/Images/clock.svg" alt={`Disabled Image`} />
                        )}{" "}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>

                <Divider />
                {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}

                <List className="List-div">
                  <p className="items-main-heading">Other Menu</p>

                  <div>
                    {filteredOtherMenuItems?.map(({ text, path , disabled}, index) => (
                      <ListItem
                        key={text}
                        disablePadding
                        selected={index === selectedotherItem}
                        
                      >
                        <ListItemButton
                          component={Link}
                          to={path}
                          selected={index === selectedotherItem}
                          disabled={disabled}
                          onClick={() => handleOtherListItemClick(index)}
                          className={
                            index === selectedotherItem
                              ? "list-item-active"
                              : "list-item"
                          }
                          sx={{
                            "& .MuiListItemText-root": {
                              fontSize: "14px",
                            },
                            "& .MuiListItemText-primary": {
                              fontSize: "14px",
                            },
                          }}
                        >
                          <ListItemIcon
                            minWidth={"24px"}
                            className="list-icons"
                            selected={index === selectedotherItem}
                            sx={{
                              "& ..MuiListItemIcon-root": {
                                minWidth: "24px",
                              },
                            }}
                          >
                            <img
                              src={otherdashicons[index]}
                              alt={`Icon ${index + 1}`}
                            />
                          </ListItemIcon>
                          <ListItemText
                            className={
                              index === selectedotherItem
                                ? "list-item-active"
                                : "list-item"
                            }
                            primary={text}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </div>
                </List>
                <div className="Logout-div">
                  <img src="/Images/Dashboard/logout.svg" alt="Logout-icon" />

                  <p className="Logout-txt">logout</p>
                </div>
              </Drawer>
            )}

            {isMobile && (
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: "#fff",
                  },
                }}
                sx={{
                  width: 250,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                  },
                }}
                variant="persistent"
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              >
                <DrawerHeader>
                  <img
                    src="/Images/Dashboard/web3fund-dash-logo.svg"
                    
                    className="ms-3"
                    alt="Logo"
                  />
                  <IconButton
                    onClick={handleDrawerClose}
                    style={{ color: "#000" }}
                  >
                    {theme.direction === "ltr" ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                </DrawerHeader>
                <Divider />
                <List className="List-div">
                  <p className="items-main-heading">General Menu</p>
                  {filteredMenuItems?.map(({ text, path, disabled }, index) => (
                    <ListItem
                      key={text}
                      disablePadding
                      disabled={disabled}
                      selected={index === selectedItem}
                      className={
                        index === selectedItem
                          ? "list-item-active"
                          : "list-item"
                      }
                    >
                      <ListItemButton
                        component={Link}
                        to={path}
                        onClick={() => handleListItemClick(index)}
                        selected={index === selectedItem}
                        className={
                          index === selectedItem
                            ? "list-item-active"
                            : "list-item"
                        }
                        sx={{
                          "& .MuiListItemText-root": {
                            fontSize: "14px",
                          },
                          "& .MuiListItemText-primary": {
                            fontSize: "14px",
                          },
                        }}
                      >
                        <ListItemIcon
                          minWidth={"24px"}
                          className="list-icons"
                          selected={index === selectedItem}
                          sx={{
                            "& ..MuiListItemIcon-root": {
                              minWidth: "24px",
                            },
                          }}
                        >
                          <img
                            src={dashicons[index]}
                            alt={`Icon ${index + 1}`}
                          />
                        </ListItemIcon>
                        <ListItemText
                          selected={index === selectedItem}
                          className={
                            index === selectedItem
                              ? "list-item-active"
                              : "list-item"
                          }
                          primary={text}
                        />
                        {disabled && (
                          <img src="/Images/clock.svg" alt={`Disabled Image`} />
                        )}{" "}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>

                <Divider />

                <List className="List-div">
                  <p className="items-main-heading">Other Menu</p>

                  <div>
                    {filteredOtherMenuItems?.map(({ text, path }, index) => (
                      <ListItem
                        key={text}
                        disablePadding
                        selected={index === selectedotherItem}
                      >
                        <ListItemButton
                          component={Link}
                          to={path}
                          onClick={() => handleOtherListItemClick(index)}
                          selected={index === selectedotherItem}
                          className={
                            index === selectedotherItem
                              ? "list-item-active"
                              : "list-item"
                          }
                          sx={{
                            "& .MuiListItemText-root": {
                              fontSize: "14px",
                            },
                            "& .MuiListItemText-primary": {
                              fontSize: "14px",
                            },
                          }}

                        >
                          <ListItemIcon
                            className="list-icons"
                            sx={{
                              "& ..MuiListItemIcon-root": {
                                minWidth: "24px",
                              },
                            }}
                            minWidth={"24px"}
                          >
                            <img
                              src={otherdashicons[index]}
                              alt={`Icon ${index + 1}`}
                            />
                          </ListItemIcon>
                          <ListItemText
                            selected={index === selectedotherItem}
                            className={
                              index === selectedotherItem
                                ? "list-item-active"
                                : "list-item"
                            }
                            primary={text}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </div>
                </List>
                <div className="Logout-div" onClick={()=>{handleLogout()}} eventKey={1} >
                  <img src="/Images/Dashboard/logout.svg" alt="Logout-icon" />

                  <p className="Logout-txt">logout</p>
                </div>
              </Drawer>
            )}

            <Main
              open={open}
              sx={{
                backgroundColor: "var(--grey-0-primary-screen-color, #F9F9FC)",

                paddingLeft: isMobile ? "50px" : "28px",
              }}
            >
              <DrawerHeader />
              {children}
            </Main>
          </Box>
        )}
      </ThemeProvider>
      {/* <Web3Modal show={wallet} setModalShows={SetWallet} /> */}
    </>
  );
}
