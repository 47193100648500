import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getFundsData } from "../middleware/fund";

const initialState = {
  loading: false,
  error: "",
  fund: [],
};
const fundDataSlice = createSlice({
  name: "fundDataSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFundsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFundsData.fulfilled, (state, action) => {
      state.loading = false;
      state.fund = action.payload;
    });
    builder.addCase(getFundsData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default fundDataSlice.reducer;
