
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const AddFund = createAsyncThunk("AddFund", async (data) => {
    try {
      console.log("inside the modal");
      const res = await api.post(`${API_URL}/api/addFundsDeposite`, data);
      // localStorage.setItem("token", res?.data?.token);
      return {
        status: res?.status,
        data: res?.data?.data,
        token: res?.data?.token,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  });


  export const getFundsData = createAsyncThunk(
    "getFundsData",
    async (data) => {
      try {
        const res = await api.get(
          `${API_URL}/api/getFundsDeposite/${data?.year}`
        );
        return {
          status: res?.status,
          data: res?.data,
        };
      } catch (error) {
        return {
          message: error?.response?.data?.error,
          status: error?.response?.status,
        };
      }
    }
  );

  export const UpdateIpfs = createAsyncThunk("UpdateIpfs", async () => {
    try {
      console.log("inside the modal");
      const res = await api.post(`${API_URL}/api/updateIpfs`, {});
    
      // localStorage.setItem("token", res?.data?.token);
      return {
        status: res?.status,
        data: res?.data,
       
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  });