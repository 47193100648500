import React from "react";
import Form from "react-bootstrap/Form";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import { useState } from "react";
import "./AccountHistoryTable.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserHistory } from "../../../../services/redux/middleware/getNft";

export default function AdminAccountTable() {
  const historyData = useSelector((state) => state?.history?.userHistory?.data);
  const [currentPage, setCurrentPage] = useState(1);
  const { id } = useParams();
  const dispatch=useDispatch()



  const ConvertDate = (originalDateStr) => {
    const originalDate = new Date(originalDateStr);
    const formattedDate = originalDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return formattedDate;
  };

  function createData(hash, action, date, description) {
    return {
      hash,
      action,
      date,
      description,
    };
  }
  const rows = [
    createData(
      "1",
      "Commission Paid",
      "02/03/2024  20:00",
      "You've received $10,000 profit share from james@gmail.com"
    ),
    createData(
      "2",
      "Sold NFT",
      "02/03/2024  20:00",
      "You've sold your M4-24 50K NFT to 0x999991000001aadddd011s for $53,000 "
    ),
    createData(
      "3",
      "Sold NFT",
      "02/04/2024  20:00",
      "You've sold your M4-24 20K NFT to 0x999991000001aadddd011s for $22,000 "
    ),
    createData(
      "4",
      "Sold NFT",
      "02/05/2024  20:00",
      "You've received an offer of $20,000 for your M4-24 20K NFT "
    ),
    createData(
      "5",
      "Offer Recieved",
      "02/05/2024  20:00",
      "Listed a M4-24 20K for $25,000"
    ),
    createData(
      "6",
      "List NFT",
      "02/05/2024  20:00",
      "Referred pooling21@gmail.com to Web3Fund"
    ),
    createData(
      "7",
      "Refer User",
      "02/05/2024  20:00",
      "Referred alanj33@gmail.com to Web3Fund"
    ),
    createData("8", "Refer User", "02/05/2024  20:00", "Minted M4-24 20K"),
    createData("9", "Mint NFT", "02/05/2024  20:00", "Minted M4-24 20K"),
    createData(
      "10",
      "Sign Up",
      "02/05/2024  20:00",
      "Congratulations on signing up to Web3Fund"
    ),
  ];

  const handlePageChange = (event, page) => {
    setCurrentPage(page);

    const data = {
      id: id,
      page: page,
    };

    dispatch(getUserHistory(data));
  };
  return (
    <>
      {historyData?.userHistory && historyData?.userHistory?.length ? (
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table
            sx={{
              minWidth: 400,
              "& .MuiTableCell-sizeMedium": {
                padding: "16px 24px",
              },
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow className="columns-name">
                <TableCell className="column-head">#</TableCell>
                <TableCell className="column-head" style={{textAlign: "center"}}>Action</TableCell>
                <TableCell className="column-head" style={{textAlign: "center"}}>Date</TableCell>
                <TableCell className="column-head" style={{textAlign: "center"}}>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historyData?.userHistory?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: "var(--Black-500---Primary, #1D1F2C)",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "160%",
                      textAlign: "start",
                    }}
                  >
                    {row?.number}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--Black-500---Primary, #1D1F2C)",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "160%",
                      textAlign: "center",
                    }}
                  >
                    {row.action}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--Black-500---Primary, #1D1F2C)",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "160%",
                      textAlign: "center",
                    }}
                  >
                    {ConvertDate(row?.txDate)}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--Black-500---Primary, #1D1F2C)",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "160%",
                      textAlign: "center",
                    }}
                  >
                    {row.description}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <hr className="hr-above"></hr>
          <Stack  spacing={8} className="stack-contain mt-2">
            <Pagination
              count={historyData?.totalPages}
              
              variant="outlined"
              shape="rounded"
              siblingCount={0}
              boundaryCount={2}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Stack>
        </TableContainer>
      ) : (
        <div className="no-referral-contain">
          <img src="/Images/Frame 4 (1).svg" alt="No History Found" />
          <p className="no-referral-txt">No history available</p>
          <p className="no-referral-des">
            You currently do not have any history associated to your account{" "}
          </p>
        </div>
      )}
    </>
  );
}
