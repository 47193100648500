import React from "react";
import "./AdminUserCard.css";

export default function AdminUserCard({ucardimg,ucardvalue,ucardh}) {
  return (
    <>
      <div className="user-card-main">
        <div>
            <img src={ucardimg} alt="img"/>
        </div>
        <div className="card-h-p">
            <p className="card-h">{ucardh}</p>
            <p className="card-value">{ucardvalue}</p>
        </div>
      </div>
    </>
  );
}
