import React, { useEffect } from "react";
import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import SideDrawer from "../../../Components/Drawer/SideDrawer";
import "./payoutRequest.css";
import PayoutData from "../../../Components/PayoutData/PayoutData";
import { useDispatch, useSelector } from "react-redux";
import { getPayoutReq } from "../../../services/redux/middleware/getNft";
import ScreenLoader from "../../../Components/loader/ScreenLoader";

const PullRequest = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDropDown, setSelectedDropDown] = useState("All Time");
  const [page, setPage] = useState(0);
  const [showTb, setShowTb] = useState(false);
  const [nftsData, setNftsData] = useState();
  const dispatch = useDispatch();
  const payoutData = useSelector((state) => state?.payout);
  console.log("this is payout data", payoutData);

  const handleModalOpen = (data) => {
    setNftsData(data);

    setShowTb(!showTb);
  };
  // const handlePageChange = (event, page) => {
  //   setCurrentPage(page);

  //   const data = {
  //     page: page,
  //   };
  //   dispatch(getAllUsers(data));
  // };

  useEffect(() => {
    dispatch(getPayoutReq());
  }, []);

  return (
    <SideDrawer showSidebar={true} style={{ overflowX: "hidden" }}>
      <PayoutData
        nftDta={nftsData}

        show={showTb}
        onHide={() => {
          setShowTb(false);
        }}
      />
      {payoutData?.loading && <ScreenLoader />}
      <div className="row-3 " style={{ overflowX: "auto" }}>
        <div className="table-above-row-info">
          <div className="search-bar">
            <div className="table-main-head-info">
              <p className="user-heading"> Payout Request </p>
              <div className="search-input-icon">
                <img
                  src="/Images/Admin-Info-Dashboard/search-icon.svg"
                  alt="search-icon"
                  className="search-icon"
                  style={{
                    position: "absolute",
                    left: "4%",
                    top: "45%",
                    transform: "translateY(-50%)",
                  }}
                />
                <input
                  type="text"
                  placeholder="Search..."
                  className="search-input"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="table-top-lhs">
            <div className="drop-main">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="dropdown-week">
                  <img src="/Images/Dashboard/calendar-2.svg" alt="/" />{" "}
                  {selectedDropDown}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("All Time");
                    }}
                  >
                    All Time
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                        onClick={() => {
                          setSelectedDropDown("This Week");
                        }}
                      >
                        This Week
                      </Dropdown.Item> */}
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("1 Year");
                    }}
                  >
                    1 Year
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("90 Days");
                    }}
                  >
                    90 Days
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("30 Days");
                    }}
                  >
                    30 Days
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("7 Days");
                    }}
                  >
                    7 Days
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <div className="table-view-img">
                <img src="/Images/Dashboard/column-contain.svg" />
                <img
                  src="/Images/Dashboard/flex-contain.svg"
                  className="flex-img"
                />
              </div> */}
          </div>
        </div>
        <>
          {payoutData?.payout?.data?.data?.length > 0 ? (
            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
              <Table sx={{ overflowX: "auto" }} aria-label="simple table">
                <TableHead>
                  <TableRow
                    className="columns-name"
                    sx={{ background: "transparent" }}
                  >
                    <TableCell className="column-head-payout">#</TableCell>
                    <TableCell
                      className="column-head-payout"
                      style={{ textAlign: "left" }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      className="column-head-payout"
                      style={{ textAlign: "left" }}
                    >
                      Wallet
                    </TableCell>
                    <TableCell
                      className="column-head-payout"
                      style={{ textAlign: "left" }}
                    >
                      NFTs
                    </TableCell>
                    {/* <TableCell className="column-head"></TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payoutData?.payout?.data?.data?.map((item, index) => {
                    return (
                      <TableRow
                        // key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "160%",
                            textAlign: "left",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // navigate(`/userProfile/${row?.user?._id}`);
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            textAlign: "left",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // navigate(`/userProfile/${row?.user?._id}`);
                          }}
                        >
                          {item?.userDetails?.name}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            textAlign: "left",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // navigate(`/userProfile/${row?.user?._id}`);
                          }}
                        >
                          {/* {row?.user?.name
                                  ? `${row?.user?.name}`
                                  : "-   -    -"} */}

                          {item?.userDetails?.walletAddress &&
                            `${item?.userDetails?.walletAddress.slice(
                              0,
                              5
                            )}...${item?.userDetails?.walletAddress.slice(-5)}`}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            textAlign: "left",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // navigate(`/userProfile/${row?.user?._id}`);
                          }}
                        >
                          {item?.payouts && item?.payouts?.length}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            // disabled={row?.nftData?.length === 0 && true}
                            // onClick={() => setOpen(!open)}
                            onClick={() => {
                              // setNftsData(row?.nftData);

                              handleModalOpen(item?.payouts);
                            }}
                          >
                            {/* {open ? ( */}
                            {showTb ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <hr className="hr-above"></hr>
              <div className="table__row">
                <p>Showing items per page</p>
                <Stack spacing={8} className="stack-contain">
                  <Pagination
                    // count={allUsers?.users?.data?.totalPages}
                    variant="outlined"
                    shape="rounded"
                    siblingCount={0}
                    boundaryCount={2}
                    // page={currentPage}
                    // onChange={handlePageChange}
                  />
                </Stack>
              </div>
            </TableContainer>
          ) : (
            "No recode found"
          )}
          {/* ) : (
                <div className="maindashboard__empty-text">
                  <img src="/Images/Admin-Info-Dashboard/Frame-4.svg" alt="/" />
                  <h6>No users available</h6>
                  <p>There is currently no user information available</p>
                </div>
              )} */}
        </>
      </div>
    </SideDrawer>
  );
};

export default PullRequest;
